import { gql } from '@apollo/client'

export default gql`
  fragment baccaratBetRecordFragments on BaccaratBetRecord {
    betNo
    bigAmount
    bigDiffAmount
    smallAmount
    smallDiffAmount
    playerAmount
    playerDiffAmount
    dealerAmount
    dealerDiffAmount
    tieAmount
    tieDiffAmount
    playerPairAmount
    playerPairDiffAmount
    dealerPairAmount
    dealerPairDiffAmount
    playerDragonAmount
    dealerDragonAmount
    playerDragonDiffAmount
    dealerDragonDiffAmount
    playerSingleAmount
    dealerSingleAmount
    playerSingleDiffAmount
    dealerSingleDiffAmount
    playerDoubleAmount
    dealerDoubleAmount
    playerDoubleDiffAmount
    dealerDoubleDiffAmount
    perfectPairAmount
    perfectPairDiffAmount
    anyPairAmount
    anyPairDiffAmount
    dealerKingAmount
    dealerKingDiffAmount
    playerKingAmount
    playerKingDiffAmount
    superTie0Amount
    superTie0DiffAmount
    superTie1Amount
    superTie1DiffAmount
    superTie2Amount
    superTie2DiffAmount
    superTie3Amount
    superTie3DiffAmount
    superTie4Amount
    superTie4DiffAmount
    superTie5Amount
    superTie5DiffAmount
    superTie6Amount
    superTie6DiffAmount
    superTie7Amount
    superTie7DiffAmount
    superTie8Amount
    superTie8DiffAmount
    superTie9Amount
    superTie9DiffAmount
    super6Amount
    super6DiffAmount
    diff
    totalBet
    ipAddress
    effectiveAmount
    effectiveXimaAmount
    winLossAmount
    createdAt
    totalWinAmount
    creditAfterWin
  }
`
