import { gql } from '@apollo/client'
import playerFragments from 'graphql/application/fragments/playerFragments'

export default gql`
  fragment niuGameFragments on NiuGame {
    dealer {
      id
      ...playerFragments
    }
    player1 {
      id
      ...playerFragments
    }
    player2 {
      id
      ...playerFragments
    }
    player3 {
      id
      ...playerFragments
    }
    status
    hasResult
    gameNo
    dealerId
    dealerCountLeft
  }

  ${playerFragments}
`
