import { Howl } from 'howler'

const host =
  window.location.hostname === 'localhost'
    ? 'vvip88.net'
    : window.location.hostname

export const audios = {
  bi: `https://assets.${host}/audios/bi.mp3`,
  beep: `https://assets.${host}/audios/beep.mp3`,
  cheer: `https://assets.${host}/audios/cheer.wav`,
  chip: `https://assets.${host}/audios/chip.wav`,
  coins: `https://assets.${host}/audios/coins.wav`,
  confirmBet: `https://assets.${host}/audios/confirm_bet.wav`,
  dealerOpen: `https://assets.${host}/audios/dealer_open.mp3`,
  dealerThird: `https://assets.${host}/audios/dealer_third.mp3`,
  dealerWin: `https://assets.${host}/audios/dealer_win.mp3`,
  dealer: `https://assets.${host}/audios/dealer.mp3`,
  fail: `https://assets.${host}/audios/fail.wav`,
  flipCard: `https://assets.${host}/audios/flip_card.wav`,
  gameMusic: `https://assets.${host}/audios/game_music.mp3`,
  gameOn: `https://assets.${host}/audios/game_on.mp3`,
  playerOpen: `https://assets.${host}/audios/player_open.mp3`,
  playerThird: `https://assets.${host}/audios/player_third.mp3`,
  playerWin: `https://assets.${host}/audios/player_win.mp3`,
  player: `https://assets.${host}/audios/player.mp3`,
  points0: `https://assets.${host}/audios/points_0.mp3`,
  points1: `https://assets.${host}/audios/points_1.mp3`,
  points2: `https://assets.${host}/audios/points_2.mp3`,
  points3: `https://assets.${host}/audios/points_3.mp3`,
  points4: `https://assets.${host}/audios/points_4.mp3`,
  points5: `https://assets.${host}/audios/points_5.mp3`,
  points6: `https://assets.${host}/audios/points_6.mp3`,
  points7: `https://assets.${host}/audios/points_7.mp3`,
  points8: `https://assets.${host}/audios/points_8.mp3`,
  points9: `https://assets.${host}/audios/points_9.mp3`,
  shuffle: `https://assets.${host}/audios/shuffle.mp3`,
  stopBet: `https://assets.${host}/audios/stop_bet.mp3`,
  tieWin: `https://assets.${host}/audios/tie_win.mp3`,
  bankerOpening: `https://assets.${host}/audios/bankerOpening.mp3`,
  back: `https://assets.${host}/audios/back.mp3`,
  click: `https://assets.${host}/audios/click.mp3`,
  popup: `https://assets.${host}/audios/popup.mp3`,
  transition: `https://assets.${host}/audios/transition.mp3`,
}

export const playList = (index, list) => {
  let sound = new Howl({
    src: [list[index]],
    preload: true,
    volume: 0.1,
    onend: () => {
      if (index + 1 === list.length) {
        // playList(0, list)
      } else {
        playList(index + 1, list)
      }
    },
  })
  sound.play()
}

export const playAudio = (selected: string) => {
  if (!audios[selected]) return
  let sound = new Howl({
    src: [audios[selected]],
    preload: true,
    autoPlay: true,
    volume: 0.1,
  })
  sound.play()
}
