import { gql } from '@apollo/client'

export default gql`
  fragment currentBaccaratBetRecordFragments on CurrentBaccaratBetRecord {
    playerAmount
    playerDiffAmount
    dealerAmount
    dealerDiffAmount
    bigAmount
    bigDiffAmount
    smallAmount
    smallDiffAmount
    super6Amount
    super6DiffAmount
    tieAmount
    tieDiffAmount
    playerPairAmount
    playerPairDiffAmount
    dealerPairAmount
    dealerPairDiffAmount
    dealerDragonAmount
    dealerDragonAmount
    playerDragonAmount
    playerDragonDiffAmount
    dealerDoubleAmount
    dealerDoubleDiffAmount
    playerDoubleAmount
    playerDoubleDiffAmount
    dealerSingleAmount
    dealerSingleDiffAmount
    playerSingleAmount
    playerSingleDiffAmount
    perfectPairAmount
    perfectPairDiffAmount
    anyPairAmount
    anyPairDiffAmount
    dealerKingAmount
    dealerKingDiffAmount
    playerKingAmount
    playerKingDiffAmount
    superTie0Amount
    superTie1Amount
    superTie2Amount
    superTie3Amount
    superTie4Amount
    superTie5Amount
    superTie6Amount
    superTie7Amount
    superTie8Amount
    superTie9Amount
    superTie0DiffAmount
    superTie1DiffAmount
    superTie2DiffAmount
    superTie3DiffAmount
    superTie4DiffAmount
    superTie5DiffAmount
    superTie6DiffAmount
    superTie7DiffAmount
    superTie8DiffAmount
    superTie9DiffAmount
    totalAmount
    totalDiffAmount
    hasResult
    winLossAmount
    gameNo
    roomName
  }
`
