import { wrap } from 'comlink'
import { useEffect, useState } from 'react'

export const useScoreBoardWorker = (
  rows: number,
  columns: number,
  results: string[],
  firstTie?: true | false,
) => {
  const [scoreBoard, setScoreBoard] = useState(null)

  useEffect(() => {
    const worker = new Worker('libs/ScoreBoard/worker', {
      name: 'initScoreBoard',
      type: 'module',
    })
    const { initScoreBoard } = wrap<
      import('libs/ScoreBoard/worker').ScoreBoardWorker
    >(worker)
    initScoreBoard(rows, columns, results, firstTie).then((board) =>
      setScoreBoard(board),
    )
  }, [rows, columns, results])

  return { scoreBoard }
}
