import bag from 'images/Gifts/bag2.png'
import flowers from 'images/Gifts/flowers.png'
import giftBox from 'images/Gifts/giftbox.png'
import hotdog from 'images/Gifts/hotdog.png'
import money from 'images/Gifts/money.png'
import pie from 'images/Gifts/pie.png'
import rocket from 'images/Gifts/rocket.png'
import chocolate from 'images/Gifts/chocolate.png'
import moneybag from 'images/Gifts/moneybag.png'
import ring from 'images/Gifts/ring.png'
import icepop from 'images/Gifts/icepop.png'
import egg from 'images/Gifts/egg.png'

const giftList = [
  { name: 'egg', title: '生氣雞蛋', value: 87, image: egg, game: true },
  { name: 'flowers', title: '花束', value: 100, image: flowers, game: true },
  { name: 'pie', title: '杯子蛋糕', value: 200, image: pie, game: true },
  {
    name: 'hotdog',
    title: '熱狗',
    value: 500,
    image: hotdog,
    game: true,
  },
  {
    name: 'chocolate',
    title: '巧克力',
    value: 666,
    image: chocolate,
    game: true,
  },
  {
    name: 'icepop',
    title: '冰棒',
    value: 777,
    image: icepop,
    game: true,
  },
  {
    name: 'giftBox',
    title: '禮物盒',
    value: 1000,
    image: giftBox,
    game: true,
  },
  // { name: 'money', title: '錢袋', value: 2000, image: money, game: true },
  {
    name: 'moneybag',
    title: '一生積蓄',
    value: 2000,
    image: moneybag,
    game: true,
  },
  { name: 'bag2', title: '愛馬仕', value: 3000, image: bag, game: true },
  { name: 'ring', title: '戒指', value: 8888, image: ring, game: true },
  { name: 'rocket', title: '火箭', value: 10000, image: rocket, game: true },
]

export default giftList
