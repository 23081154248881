import girlFragments from 'graphql/application/fragments/girlFragments'

import { gql } from '@apollo/client'

export default gql`
  fragment baccaratRoomFragments on BaccaratRoom {
    waitingBetSeconds
    name
    girl {
      id
      ...girlFragments
    }
    streamName
    streamKey
    currentRoadsImage
    roads
    latency
    alertText
    waitingText
    zoomType
    defaultScale
    status
    streams {
      id
      name
      key
    }
    isBlockchainRoom
    disableChatAndGift
    defaultPlayer
    cfWebrtcPlaybackUrl
  }

  ${girlFragments}
`
