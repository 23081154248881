import { gql } from '@apollo/client'
import profileFragments from 'graphql/application/fragments/profileFragments'

export default gql`
  mutation sendBaccaratGift($input: SendBaccaratGiftInput!) {
    sendBaccaratGift(input: $input) {
      profile {
        id
        ...profileFragments
      }
      errors {
        message
        attribute
      }
    }
  }

  ${profileFragments}
`
