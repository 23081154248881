import { gql } from '@apollo/client'
import baccaratBetRecordFragments from 'graphql/application/fragments/baccaratBetRecordFragments'
import baccaratGameFragments from 'graphql/application/fragments/baccaratGameFragments'

export default gql`
  query getBaccaratGame($betNo: String!) {
    baccaratGame(betNo: $betNo) {
      id
      ...baccaratGameFragments
      video
    }
    baccaratBetRecord(betNo: $betNo) {
      id
      ...baccaratBetRecordFragments
    }
  }

  ${baccaratGameFragments}
  ${baccaratBetRecordFragments}
`
