import { gql } from '@apollo/client'

export default gql`
  fragment playerFragments on Player {
    betAmount
    name
    card1
    card2
    card3
    card4
    card5
    bestCards
    result
    isWinner
  }
`
