import React from 'react'
import { capitalize } from 'lodash'
import { withProfiler } from '@sentry/react'
import giftList from 'libs/giftList'

const Gift = ({ gift, size = 56 }) => {
  return (
    <img
      src={giftList.find((item) => item.name === gift).image}
      width={size}
      height={size}
    />
  )
}
export default withProfiler(React.memo(Gift))
