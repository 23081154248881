import { Box, Grid } from '@material-ui/core'
import Card from 'components/common/Card'
import React from 'react'
import { withProfiler } from '@sentry/react'

const Center: React.FC = ({ children }) => (
  <Box display="flex" justifyContent="center" alignItems="center" width="100%">
    {children}
  </Box>
)

const BestCards = ({ cards }) => {
  return (
    <Grid container spacing={0}>
      <Grid item xs={6}>
        <Center>
          {cards && cards[0] && (
            <Card card={cards[0]} on selected soundOn width={30} />
          )}
        </Center>
      </Grid>
      <Grid item xs={6}>
        <Center>
          {cards && cards[1] && (
            <Card card={cards[1]} on selected soundOn width={30} />
          )}
        </Center>
      </Grid>
      <Grid item xs={4}>
        <Center>
          {cards && cards[2] && (
            <Card card={cards[2]} on selected soundOn width={30} />
          )}
        </Center>
      </Grid>
      <Grid item xs={4}>
        <Center>
          {cards && cards[3] && (
            <Card card={cards[3]} on selected soundOn width={30} />
          )}
        </Center>
      </Grid>
      <Grid item xs={4}>
        <Center>
          {cards && cards[4] && (
            <Card card={cards[4]} on selected soundOn width={30} />
          )}
        </Center>
      </Grid>
    </Grid>
  )
}

export default withProfiler(React.memo(BestCards))
