import { gql } from '@apollo/client'
import betResultFragments from 'graphql/application/fragments/betResultFragments'

export default gql`
  fragment betRecordFragments on BetRecord {
    player1Amount
    player2Amount
    player3Amount
    player1DiffAmount
    player2DiffAmount
    player3DiffAmount
    player1Result
    player2Result
    player3Result
    allResult
    totalBet
    diff
    fee
    createdAt
    betNo
    isDealer
    betResult {
      id
      ...betResultFragments
    }
  }
  ${betResultFragments}
`
