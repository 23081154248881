import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { moneyFormat } from 'helpers/index'
import { compact } from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import BestCards from './BestCards'
import { withProfiler } from '@sentry/react'

const useStyles = makeStyles((theme) => ({
  result: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 500,
    color: '#FBD75F',
    marginTop: theme.spacing(1),
    minHeight: 20,
  },
  root: {
    background: 'linear-gradient(128.22deg, #651402 6.8%, #30120C 95.05%)',
    height: '100%',
    // minHeight: '139px',
    flexGrow: 1,
    borderRadius: '4px',
    padding: '8px 12px',
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  name: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
  winner: {
    border: `1px solid #FBD75F`,
  },
  totalBet: {
    color: '#FBD75F',
    fontSize: theme.typography.pxToRem(12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    minHeight: 20,
  },
}))

const Player = ({ game, name, player }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const getResult = (result) => {
    if (result < 0) return t('screens.room.none')
    if (result === 20) return t('screens.room.odds20')
    if (result === 50) return t('screens.room.odds50')
    if (result) return `牛${result}`
    return null
  }

  const cards = useMemo(() => {
    return compact(
      game[player].bestCards || [
        game[player].card1,
        game[player].card2,
        game[player].card3,
        game[player].card4,
        game[player].card5,
      ],
    )
  }, [game])

  return (
    <Box
      className={clsx(
        classes.root,
        game[player].isWinner ? classes.winner : null,
      )}
    >
      <Box className={classes.name}>{name}</Box>
      <Box minHeight={64.85}>
        <BestCards cards={cards} />
      </Box>
      <Box className={classes.result}>{getResult(game[player].result)}</Box>
      <Box className={classes.totalBet}>
        <Box mr={1}>{t('screens.room.totalBet')}</Box>
        {moneyFormat(game[player].betAmount)}
      </Box>
    </Box>
  )
}

export default withProfiler(React.memo(Player))
