import { gql } from '@apollo/client'

export default gql`
  fragment baccaratMessageFragments on BaccaratMessage {
    type
    body
    createdAt
    gift
    user {
      id
      username
      avatar
    }
  }
`
