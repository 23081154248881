import { gql } from '@apollo/client'

export default gql`
  fragment betResultFragments on BetResult {
    totalAmount
    player1Bet
    player1Result
    player2Bet
    player2Result
    player3Bet
    player3Result
  }
`
