import React, { useState } from 'react'
import { Box, Typography, Popover } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useCopyToClipboard } from 'react-use'
import { useTranslation } from 'react-i18next'
import { withProfiler } from '@sentry/react'

import COPY from './copy.svg'

const useStyles = makeStyles((theme) => ({
  root: {},
  copied: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    color: theme.palette.common.white,
  },
  btn: {
    cursor: 'pointer',
  },
}))

const CopyBtn = ({ width = 13, height = 14, text }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [copyState, setCopyState] = useState({
    open: false,
    anchorEl: null,
  })
  const [state, copyToClipboard] = useCopyToClipboard()

  const copy = (event) => {
    copyToClipboard(text)
    setCopyState({
      open: true,
      anchorEl: event.currentTarget,
    })
    setTimeout(() => {
      setCopyState({
        open: false,
        anchorEl: null,
      })
    }, 3000)
  }

  return (
    <Box ml={1}>
      <Box
        className={classes.btn}
        aria-describedby="referralLink"
        onClick={(e) => copy(e)}
      >
        <img src={COPY} style={{ width, height }} />
      </Box>
      <Popover
        open={copyState.open}
        anchorEl={copyState.anchorEl}
        className={classes.copied}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Typography className={classes.copied}>
          {t('screens.profile.copied')}
        </Typography>
      </Popover>
    </Box>
  )
}

export default withProfiler(React.memo(CopyBtn))
