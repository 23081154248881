import { gql } from '@apollo/client'

export default gql`
  mutation updateSettings($input: UpdateSettingsInput!) {
    updateSettings(input: $input) {
      profile {
        id
        settings
      }
      errors {
        attribute
        message
      }
    }
  }
`
