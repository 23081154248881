import { gql } from '@apollo/client'

export default gql`
  fragment baccaratGameFragments on BaccaratGame {
    baccaratRoomId
    playerWin
    dealerWin
    tieWin
    playerPairWin
    dealerPairWin
    dealerPoints
    playerPoints
    gameNo
    status
    playerCards
    dealerCards
    shuffle
    playerNeedAnother
    dealerNeedAnother
    dealerAmount
    playerAmount
    dealerPairAmount
    playerPairAmount
    tieAmount
    updatedAt
    endAt
    targets
  }
`
