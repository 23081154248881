const registerServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register('/service-worker.js', { scope: './' })
        .then((registration) => {
          // console.log('SW registered: ', registration)
        })
        .catch((registrationError) => {
          console.log('SW registration failed: ', registrationError)
        })
    })
    window.addEventListener('install', () => {
      console.log('install')
    })
  }
}

export default registerServiceWorker
