import { gql } from '@apollo/client'
import betRecordFragments from 'graphql/application/fragments/betRecordFragments'
import niuGameFragments from 'graphql/application/fragments/niuGameFragments'

export default gql`
  query getBetRecord($betNo: String!) {
    betRecord(betNo: $betNo) {
      id
      niuGame {
        id
        ...niuGameFragments
      }
      ...betRecordFragments
    }
  }

  ${betRecordFragments}
  ${niuGameFragments}
`
