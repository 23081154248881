import { isMobile, isTablet } from 'react-device-detect'
import { useOrientation } from 'react-use'

const useMobile = () => {
  const state = useOrientation()
  if (isMobile && !isTablet) {
    return true
  } else if (isMobile && isTablet && state.angle !== 0) {
    return false
  } else if (isMobile && isTablet && state.angle === 0) {
    return true
  } else {
    return false
  }
}

export default useMobile
