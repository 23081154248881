import { useEffect } from 'react'

import { gql, useMutation } from '@apollo/client'

const MUTATION = gql`
  mutation pingRoom(
    $roomType: String!
    $roomId: ID!
    $forceJoinMessage: Boolean
  ) {
    pingRoom(
      input: {
        roomType: $roomType
        roomId: $roomId
        forceJoinMessage: $forceJoinMessage
      }
    ) {
      status
    }
  }
`

export default function usePingRoom(channel) {
  const [pingRoom] = useMutation(MUTATION)

  useEffect(() => {
    const timer = setInterval(() => {
      pingRoom({ variables: channel })
    }, 30000)

    pingRoom({ variables: { ...channel, forceJoinMessage: true } })

    return () => clearInterval(timer)
  }, [channel])
}
