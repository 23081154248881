export type Options = {
  bigRoadLogs?: boolean
  bigEyeRoadLogs?: boolean
  smallRoadLogs?: boolean
  cockroachRoadLogs?: boolean
  firstTie?: boolean
}
export enum GameResult {
  Tie,
  BankerWin,
  PlayerWin,
}

export enum PairResult {
  NoPair,
  BankerPair,
  PlayerPair,
  AllPair,
}

export class RoundResult {
  public constructor(
    public readonly order: number, // 用于折行后确认先后关系
    public readonly result: number,
    public readonly gameResult: GameResult,
    public readonly pairResult: PairResult,
  ) {}

  /**
   * 得到新的 RoundResult 实例
   */
  public static from(source: RoundResult): RoundResult {
    return new RoundResult(
      source.order,
      source.result,
      source.gameResult,
      source.pairResult,
    )
  }
}

/**
 * 下三路所共用的 RoadItem 结构
 */
export type DownRoadItem = Readonly<{
  /**
   * RoundResult 对应的顺序
   */
  order?: number // 用于折行后确认先后关系
  /**
   * repetition === true 为红色 否则为蓝色
   */
  repetition: boolean // (大路中)是否与前面指定列指定位置均有值
}>

/**
 * DownRoadGap 下三路进行对比的列数间隔
 * 大眼路/小路/小强路分别对应1/2/3
 * @enum {number}
 */
export const enum DownRoadGap {
  BigEyeRoadGap = 1,
  SmallRoadGap = 2,
  CockroachRoadGap = 3,
}
