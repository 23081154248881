import { gql } from '@apollo/client'

export default gql`
  query myRecords($page: Int, $perPage: Int, $type: String) {
    myRecords(page: $page, perPage: $perPage, type: $type) {
      records {
        id
        info
        createdAt
      }
      totalCount
      totalPages
      currentPage
      currentCount
    }
  }
`
