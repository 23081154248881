import { gql } from '@apollo/client'
import baccaratMessageFragments from 'graphql/application/fragments/baccaratMessageFragments'

export default gql`
  mutation createBaccaratMessage($input: CreateBaccaratMessageInput!) {
    createBaccaratMessage(input: $input) {
      baccaratMessage {
        id
        ...baccaratMessageFragments
      }
      errors {
        message
        attribute
      }
    }
  }

  ${baccaratMessageFragments}
`
