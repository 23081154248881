import { gql } from '@apollo/client'
import baccaratGameFragments from 'graphql/application/fragments/baccaratGameFragments'
import baccaratRoomFragments from 'graphql/application/fragments/baccaratRoomFragments'
import currentBaccaratBetRecordFragments from '../fragments/currentBaccaratBetRecordFragments'

export default gql`
  query getBaccaratRoom($id: ID!) {
    baccaratRoom(id: $id) {
      id
      ...baccaratRoomFragments
      transform
      currentGame {
        id
        ...baccaratGameFragments
      }
      currentBaccaratBetRecord {
        id
        ...currentBaccaratBetRecordFragments
      }
    }
  }

  ${baccaratRoomFragments}
  ${baccaratGameFragments}
  ${currentBaccaratBetRecordFragments}
`
